import BigNumber from "bignumber.js";
import { SyntheticEvent } from "react";
import { Token } from "./types";
import { useGatewayApi } from "~/rdt/gateway";
import { StateEntityFungiblesPageRequest } from "@radixdlt/radix-dapp-toolkit";

BigNumber.config({
	DECIMAL_PLACES: 18,
	EXPONENTIAL_AT: 18,
	ROUNDING_MODE: BigNumber.ROUND_DOWN
});

export const MIN_USD_TVL = 750;
export const ALPHADEX_PLATFORM_ID = 7;

export const DAPP_DEFINITION_ACCOUNT_ADDRESS = 'account_rdx128y905cfjwhah5nm8mpx5jnlkshmlamfdd92qnqpy6pgk428qlqxcf';

export const ASTRL_RESOURCE_ADDRESS = 'resource_rdx1t4tjx4g3qzd98nayqxm7qdpj0a0u8ns6a0jrchq49dyfevgh6u0gj3';
export const OLD_ASTRL_RESOURCE_ADDRESS = 'resource_rdx1t5ga7j04ek4laprf7xryxlu4hl04373sskdaaptfnjhnsns98vma8m';

export const FEE_COMPONENT_ADDRESS = 'component_rdx1cpxvjgh5a0mewtzkx7j8gzpq0dh676gq5t4hh3svjm02xwakmxa6l7';
export const STAKING_COMPONENT_ADDRESS = 'component_rdx1cz6ed6wksa2u4a3da5qfeg4tg453tczexulcf0z7vs885rkr6r6363';
export const STAKING_POOL_ADDRESS = 'pool_rdx1c325zs6dz3un8ykkjavy9fkvvyzarkaehgsl408qup6f95aup3le3w';

export const sASTRL_RESOURCE_ADDRESS = 'resource_rdx1tkm0z9pkz0e59yf2258drzjwaq7axtcf2mupjz2ag9kwww4jvdfd6c';

export const DFP2_RESOURCE_ADDRESS = 'resource_rdx1t5ywq4c6nd2lxkemkv4uzt8v7x7smjcguzq5sgafwtasa6luq7fclq';
export const XRD_RESOURCE_ADDRESS = 'resource_rdx1tknxxxxxxxxxradxrdxxxxxxxxx009923554798xxxxxxxxxradxrd';

export const RADIXAPI_TOKEN = 'nHouoY0MBdBib_o0Xxl9YrcLl1QCMVOA7_R3zMHfbqc';

export const START_XRD_AMOUNT = 1000;

export const bigZero = new BigNumber(0);
export const oneE18 = new BigNumber(10).pow(18);

type DEX = {
	name: string;
	url: string;
}

type AvailableDexes = {
	[name: string]: DEX
}

export const FAV_TOKENS = [
	XRD_RESOURCE_ADDRESS,
	ASTRL_RESOURCE_ADDRESS,
	'resource_rdx1t5kmyj54jt85malva7fxdrnpvgfgs623yt7ywdaval25vrdlmnwe97', // HUG
	'resource_rdx1t4kc5ljyrwlxvg54s6gnctt7nwwgx89h9r2gvrpm369s23yhzyyzlx', // WOWO
	DFP2_RESOURCE_ADDRESS,
	// 'resource_rdx1t5xv44c0u99z096q00mv74emwmxwjw26m98lwlzq6ddlpe9f5cuc7s', // EARLY
	'resource_rdx1t52pvtk5wfhltchwh3rkzls2x0r98fw9cjhpyrf3vsykhkuwrf7jg8', // OCI
	// 'resource_rdx1t5pyvlaas0ljxy0wytm5gvyamyv896m69njqdmm2stukr3xexc2up9', // FLOOP
	// 'resource_rdx1tkk83magp3gjyxrpskfsqwkg4g949rmcjee4tu2xmw93ltw2cz94sq', // CAVIAR
	// 'resource_rdx1tk3fxrz75ghllrqhyq8e574rkf4lsq2x5a0vegxwlh3defv225cth3', // WEFT

	'resource_rdx1t4upr78guuapv5ept7d7ptekk9mqhy605zgms33mcszen8l9fac8vf', // USDC
	// 'resource_rdx1thrvr3xfs2tarm2dl9emvs26vjqxu6mqvfgvqjne940jv0lnrrg7rw', // USDT
	// 'resource_rdx1th88qcj5syl9ghka2g9l7tw497vy5x6zaatyvgfkwcfe8n9jt2npww', // xETH
	'resource_rdx1t580qxc7upat7lww4l2c4jckacafjeudxj5wpjrrct0p3e82sq4y75', // xwBTC

	
];

const DEXES: AvailableDexes = {
	OciSimple: {
		name: 'Ociswap',
		url: 'https://ociswap.com'
	},
	DefiPlaza: {
		name: 'DefiPlaza',
		url: 'https://radix.defiplaza.net/swap'
	},
	Astrolescent: {
		name: 'Astrolescent',
		url: 'https://astrolescent.com/'
	},
	OciMigration: {
		name: 'Migrate token',
		url: 'https://ociswap.com'
	},
	CaviarMigration: {
		name: 'Migrate token',
		url: 'https://caviarnine.com'
	},
	Astro_Multi: {
		name: 'Astrolescent Pool',
		url: 'https://astrolescent.com'
	},
	Caviar_Shaped: {
		name: 'CaviarNine',
		url: 'https://caviarnine.com'
	},
	AlphaDEX: {
		name: 'DeXter',
		url: 'https://astrolescent.com/limit'
	},
	OciPrecision: {
		name: 'Ociswap',
		url: 'https://ociswap.com'
	},
	RadixPlanetIndex: {
		name: 'RadixPlanet',
		url: 'https://ociswap.com'
	},

	
};

export function prettyExchangeName(pool: string) {
	const dex = DEXES[pool];

	if (dex) {
		return dex;
	}
	
	return {
		name: pool,
		url: ''
	}
}

export function roundToDecimals(amount: number, decimals?: number) {
	// const exp = Math.pow(10, decimals);
	// console.log(amount, decimals, exp, bigAmount, bigAmount / exp)
	// return Math.round(amount * exp) / exp;
	// decimals = 6;

	// if (amount < Math.pow(10, -5)) {
	// 	decimals = 7;
	// } else if (amount > Math.pow(10, 5)) {
	// 	decimals = 3;
	// }

	if (!decimals) {
		decimals = 6;
	}

	const rounded = (+amount).toLocaleString(undefined, { /*minimumSignificantDigits: decimals,*/ maximumSignificantDigits: decimals })

	return rounded;
}

export function prettyValue(value: number | string, asObject?: boolean) {
	value = +value;

	const decimals = 4;
	let size = 'M';
	let sign = '';
	let prettyValue = value.toFixed(6);

	if (value < 0) {
		sign = '-';
	}

	value = Math.abs(value);

	if (value == 0) {
		prettyValue = '0';
		size = '';
		// } else if (value < 0.0001) {
		// 		prettyValue = value.toFixed();
		// 		size = '';
		// } else if (value < 0.01) {
		// 	prettyValue = value.toFixed(6);
		// 	size = '';
		// } else if (value < 1) {
		// 	prettyValue = value.toFixed(4);
		// 	size = '';
		// } else if (value < 100) {
		// 	prettyValue = value.toFixed(2);
		// 	size = '';
		// } else if (value < 1000) {
		// 	prettyValue = value.toFixed(1);
		// 	size = '';
	} else if (value < 100000) {
		return sign + value.toLocaleString(undefined, {maximumSignificantDigits: decimals} )
		size = '';
	// } else if (value < 100000) {
	// 	prettyValue = (value / 1000).toFixed(1);
	// 	size = 'K';
	} else if (value < 1000000) {
		prettyValue = Math.round(value / 1000).toFixed(2);
		size = 'K';
	} else if (value < 10000000) {
		// 10M
		prettyValue = (value / 1000000).toFixed(2);
		size = 'M';
	} else if (value < 999999999) {
		// 999M
		prettyValue = (value / 1000000).toFixed(1);
		size = 'M';
	} /*else if (value < 10000000000) {
		// 10B
		prettyValue = (value / 1000000000).toFixed(2);
		size = 'B';
	}*/ else if (value < 1000000000000) {
		// 999B
		prettyValue = (value / 1000000000).toFixed(0);
		size = 'B';
	} else {
		// switch to exponential for weird edge cases
		const v = new BigNumber(value);
		prettyValue = v.toExponential(2);
	}

	// if (asObject) {
	// 	return {
	// 		value: prettyValue,
	// 		size: size,
	// 	};

	// }
	
	return sign + (+prettyValue).toLocaleString() + size;
}

export function prettyCurrency(value: number | string) {
	value = +value;

	let size = 'M';
	let sign = '';
	let prettyValue = value.toFixed(6);

	if (value < 0) {
		sign = '-';
	}

	value = Math.abs(value);

	if (value < 100) {
		prettyValue = value.toFixed(2);
		size = '';
	} else if (value < 10000) {
		prettyValue = Math.round(value).toFixed(0);
		size = '';
	} else if (value < 100000) {
		prettyValue = (value / 1000).toFixed(1);
		size = 'K';
	} else if (value < 1000000) {
		prettyValue = Math.round(value / 1000).toLocaleString();
		size = 'K';
	} else if (value < 10000000) {
		// 10M
		prettyValue = (value / 1000000).toFixed(2);
	} else if (value < 999999999) {
		// 999M
		prettyValue = (value / 1000000).toFixed(1);
	} else {
		// switch to exponential for weird edge cases
		const v = new BigNumber(value);
		prettyValue = v.toExponential(2);
	}

	return sign + prettyValue.toLocaleString() + size;
}

export function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

export function getTokenIcon(token: Token) {
	if (token.lpToken) {
		return getLPTokenIcon(token.name);
	}

	return getTokenIconBySymbol(token.symbol);
}

export function getIconUrl(url: string, size?: number) {
	if (!url) {
		return '/assets/img/tokens/_missing-icon.png';
	}
	return url ? `https://astrolescent.com/cdn-cgi/image/width=${size ? size * 2 : 48},height=${size ? size * 2 : 48},fit=scale-down,quality=85/${url}` : '/assets/img/tokens/_missing-icon.png';
}

export function getTokenIconBySymbol(symbol: string) {
	// if (!symbol) {
	// 	symbol = '_missing-icon';
	// }

	return `/assets/img/tokens/${symbol.toLowerCase()}.png`;
}

export function getLPTokenIcon(name: string) {
	let symbol = '_missing-icon';

	if (name.indexOf('DefiPlaza') > -1) {
		symbol = 'dfp2';
	}
	if (name.indexOf('OciSwap') > -1) {
		symbol = 'oci';
	}
	if (name.indexOf('Caviar') > -1) {
		symbol = 'caviar';
	}
	if (name.indexOf('Astrolescent') > -1) {
		symbol = 'astrl';
	}

	return getTokenIconBySymbol(symbol);
}

export function onIconError(evt: SyntheticEvent<HTMLImageElement, Event>) {
	evt.target.onError = null;
	(evt.target as HTMLImageElement).src = '/assets/img/tokens/_missing-icon.png';
}

export function shortenAddress(address: string | null): string {
	if (!address) {
		return '';
	}

	return address.substring(0, 7) + '...' + address.substring(address.length - 6);
}

const jsonHeaders = {
	"content-type": "application/json;charset=UTF-8",
	"Access-Control-Allow-Origin": "*",
	"Access-Control-Allow-Methods": "GET,HEAD,POST,OPTIONS",
}

export function jsonResponse(jsonBody: any): Response {
	return new Response(JSON.stringify(jsonBody), { headers: jsonHeaders });
}

export async function fetchTokensFromWallet(address: string) {
	const { state } = useGatewayApi;

	let resources = [];
	let firstRun = true;
	let nextCursor;
	let stateVersion;

	while (firstRun || !!nextCursor) {
		firstRun = false;

		let query: StateEntityFungiblesPageRequest = {
			address
		}

		if (nextCursor) {
			query = {
				address,
				cursor: nextCursor,
				at_ledger_state: {
					state_version: stateVersion
				}
			}
		}

		try {
			const walletState = await state.innerClient.entityFungiblesPage({
				stateEntityFungiblesPageRequest: query
			});

			nextCursor = walletState.next_cursor;
			stateVersion = walletState.ledger_state.state_version;


			for (let item of walletState.items) {
				if (parseFloat(item.amount) == 0) {
					continue;
				}

				resources.push({
					address: item.resource_address,
					amount: item.amount
				});
			}
		}
		catch (err) {
			console.error(err);
		}
	}

	return resources;
}


export async function fetchTokensDetails(addresses: string[]) {
	const { state } = useGatewayApi;

	let tokens = [];

	try {
		const tokenDetails = await state.getEntityDetailsVaultAggregated(addresses);

		for (let lpTokenDetail of tokenDetails) {
			let symbol = 'UNKNOWN', name = '', iconUrl = '', infoUrl = '', description = '';

			for (let meta of lpTokenDetail.metadata.items) {
				if (meta.key == 'symbol') {
					// @ts-ignore
					symbol = meta.value.typed.value;
				}

				if (meta.key == 'name') {
					// @ts-ignore
					name = meta.value.typed.value;
				}

				if (meta.key == 'icon_url') {
					// @ts-ignore
					iconUrl = meta.value.typed.value;
				}

				if (meta.key == 'info_url') {
					// @ts-ignore
					infoUrl = meta.value.typed.value;
				}

				if (meta.key == 'description') {
					// @ts-ignore
					description = meta.value.typed.value;
				}
			}

			const token: Token = {
				symbol,
				name,
				iconUrl,
				description,
				infoUrl,
				address: lpTokenDetail.address,
				totalSupply: lpTokenDetail.details?.total_supply
			}

			tokens.push(token)
		}
	}
	catch (err) {
		console.error(err);
	}

	return tokens;
}

export function sortTokenList(tokenList: Token[]): Token[] {

	let tokens: Token[] = [];
	let xrd: Token;
	let astrl: Token;
	let dfp2: Token;

	for (let token of tokenList) {
		if (token.lpToken) {
			continue;
		}

		if (token.address === ASTRL_RESOURCE_ADDRESS) {
			astrl = token;
			continue;
		}
		if (token.address === XRD_RESOURCE_ADDRESS) {
			xrd = token;
			continue;
		}
		// if (token.address === DFP2_RESOURCE_ADDRESS) {
		//    dfp2 = token;
		//    continue;
		// }

		tokens.push(token);

	}

	tokens.sort((a: Token, b: Token) => +a.tvl < +b.tvl ? 1 : -1);

	// tokens.unshift(dfp2);
	if (astrl) {
		tokens.unshift(astrl);	
	}
	
	if (xrd) {
		tokens.unshift(xrd);
	}

	return tokens;
}

export function secondsToDuration(sec_num: number) {
	const hours = Math.floor(sec_num / 3600);
	const minutes = Math.floor((sec_num - (hours * 3600)) / 60);
	const seconds = Math.ceil(sec_num - (hours * 3600) - (minutes * 60));

	let duration = '';

	if (hours > 0) {
		duration += hours + 'h';
	}

	if (minutes > 0) {
		duration += minutes + 'm';
	}

	if (seconds > 0) {
		duration += seconds + 's';
	}

	return duration;
}

export function getThousandSeperator() {
	const thousandSeperator = new Intl.NumberFormat().formatToParts(1000).find((part) => part.type === "group")?.value || ",";
	const decimalSeperator = getDecimalSeperator();

	// apparently this happens (seeing the errors in Sentry)
	if (thousandSeperator == decimalSeperator) {
		if (decimalSeperator == ',') {
			return '.'
		}

		return ',';
	}

	return thousandSeperator
}

export function getDecimalSeperator() {
	return new Intl.NumberFormat().formatToParts(1.1).find((part) => part.type === "decimal")?.value || ".";
}